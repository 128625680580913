.p-sidebar-right .p-sidebar {
  width: 25vw;
  background: linear-gradient(180deg, $MALIBY, $DODGER_BLUE);
  color: white;
  border: none;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  color: white;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
  display: flex;
  justify-content: flex-end;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  box-shadow: none;
}

a {
  color: white !important;
}

.rights-reserved {
  font-size: 13px;

  p {
    margin-bottom: 0;
  }
}

.layout-characteristics {
  .main-title {
    font-size: 40px;
  }

  .title {
    margin-top: 1rem;
    font-size: 20px;
  }

  .content {
    color: $SCORPION;
  }

  .characteristics-margins {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  @media only screen and (max-width: 768px) {
    .main-title {
      font-size: 2rem;
    }

    .characteristics-margins {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}

.layout-partner-carousel {
  .partner {
    border: 1px solid $MINE_SHAFT_80_OPACITY;
    text-align: center;
    max-width: 300px;
    align-self: center;
    height: 150px;
  }

  .header-title {
    font-size: 40px;
  }

  .name {
    color: $SCORPION;
  }

  .partner img {
    width: 60px;
    height: 60px;
    border: 1px solid $SCORPION_OPACITY_50;
    padding: 10px;
    border-radius: 50%;
  }

  .p-carousel-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }

  .partnersList {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .partnersCarousel {
      display: none;
    }

    .partnersList {
      display: grid;
      justify-content: center;
    }

    .header-title {
      font-size: 20px;
    }
  }
}

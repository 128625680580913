.layout-presentation-form {
  .label {
    color: $MALIBY;
  }

  .schedule_presentation_cards {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .header-title {
    font-size: 40px;
  }

  .p-inputtext,
  .p-dropdown {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0em;
  }

  .p-inputtext:focus {
    border-color: $MALIBY;
    box-shadow: 0 2px 0 rgba(38, 143, 255, 0.2);
  }

  .p-dropdown:focus-visible {
    box-shadow: none;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: $SCORPION;
  }

  .back-icon {
    color: $DODGER_BLUE;
    font-size: 1.75rem;
  }

  .back-title {
    color: $DODGER_BLUE;
  }

  .schedule_presentation_button {
    position: absolute;
    align-self: flex-end;
    right: 8rem;
  }

  @media only screen and (max-width: 768px) {
    .header-title {
      font-size: 1.5rem;
    }

    .schedule_presentation_cards {
      margin-left: 0rem;
      margin-right: 0rem;
    }

    .schedule_presentation_button {
      align-self: flex-start;
      right: auto;
    }

    .schedule_presentation_button_width {
      width: 70vw;
    }

    .backToTopButton {
      margin-top: 3rem;
    }
  }
}
.p-dropdown-panel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.p-dropdown:not(.p-disabled).p-focus {
  border: none;
  box-shadow: none;
}

.p-dropdown-item {
  color: $SCORPION !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: transparent;
  color: $MALIBY !important;
}

.layout-benefits {
  .main-title {
    font-size: 40px;
  }

  .title {
    font-size: 30px;
  }

  .content {
    color: $SCORPION;
    font-size: 20px;
  }

  img {
    width: 45vw;
  }

  @media only screen and (max-width: 768px) {
    .main-title {
      font-size: 2rem;
    }

    .title {
      font-size: 1.5rem;
    }
  }
}

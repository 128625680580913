.layout-navbar {
  background-color: $MINE_SHAFT_80_OPACITY;
  font-weight: 700;
  color: $MINE_SHAFT;
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .menu-button {
    background-color: white;
    color: $DODGER_BLUE;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0px -1px 10px 1px $MALIBY;
  }

  .p-dialog {
    height: 100vh !important;
  }

  .schedule-presentation {
    color: $primaryColor;
    background-color: #ffffff !important;
    font-weight: bold;
  }

  @media only screen and (max-width: 768px) {
    background-color: #383838 !important;
    color: #ffffff;

    .menu-button {
      background-color: #383838;
      color: #ffffff;
      border-radius: 0%;
      padding: 0;
      box-shadow: none;
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  // on top of images
  z-index: 3;
}

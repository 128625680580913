.layout-reviews-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;

  .review {
    border: 1px solid lightblue;
    text-align: center;
    max-width: 50vw;
    align-self: center;
    background-color: #eff8ff;
  }

  .header-title {
    font-size: 40px;
  }

  .content {
    color: $SCORPION;
  }

  .review img {
    width: 80px;
    height: 80px;
    border: 1px solid $SCORPION_OPACITY_50;
    border-radius: 50%;
  }

  .p-carousel-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }

  .p-carousel {
    max-width: 50vw;
  }

  @media only screen and (max-width: 768px) {
    .header-title {
      font-size: 1.5rem;
    }

    .p-carousel {
      max-width: 100%;
    }

    .review {
      max-width: 100%;
    }
  }
}

.layout-footer {
  background: linear-gradient(180deg, $MALIBY, $DODGER_BLUE);
  color: white;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .rights-reserved {
    font-size: 13px;
  }

  .footerIcons {
    justify-content: center;
    padding-right: 2rem;
  }

  p {
    margin-bottom: 0;
  }

  .d-grid {
    display: grid;
  }

  @media only screen and (max-width: 768px) {
    .grid-justify-content-center {
      display: grid;
      justify-content: center;
    }

    .inline-display {
      display: inline !important;
    }
  }
}

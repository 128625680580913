.layout-header {
  background: linear-gradient(180deg, $SOLITUDE, $DODGER_BLUE);

  .header-title {
    font-size: 40px;
  }

  .header-content {
    font-size: 20px;
  }

  .schedule-presentation {
    color: $primaryColor;
    background-color: #ffffff !important;
    font-weight: bold;
    box-shadow: 1px 1px 4px 3px $MALIBY;
  }

  .schedule-presentation:hover {
    background-color: $primaryColor !important;
    color: white !important;
  }

  @media only screen and (max-width: 768px) {
    .header-title {
      font-size: 1.5rem;
    }

    .header-content {
      font-size: 1rem;
    }
  }
}

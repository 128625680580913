.react-3d-carousel {
  height: auto !important;
  aspect-ratio: 2.8;
}

.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
  opacity: 0.3;
  transform: translateX(35%) scale(0.8) !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
  opacity: 0.3;
  transform: translateX(-35%) scale(0.8) !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
}

.react-3d-carousel .slider-container .slider-right {
  left: 110% !important;
}

.react-3d-carousel .slider-container .slider-left {
  right: 110% !important;
}

.react-3d-carousel .slider-container .slider-right div,
.react-3d-carousel .slider-container .slider-left div {
  color: $primaryColor !important;
  border-radius: 50%;
  border: none !important;
  background-color: white;
  box-shadow: 0px 2px 3px 0px $primaryColor;
  padding: 0px !important;
}

.fa-arrow-right::before {
  content: "\e901";
  font-family: "primeicons";
  font-weight: bold;
}

.fa {
  padding: 15px;
}

@media only screen and (max-width: 600px) {
  .fa {
    padding: 0.5rem;
  }

  .react-3d-carousel .slider-container .slider-right {
    left: 110% !important;
    height: 120% !important;
    margin-left: -0.5rem !important;
  }

  .react-3d-carousel .slider-container .slider-left {
    right: 110% !important;
    height: 120% !important;
    margin-right: -0.5rem !important;
  }
}

.fa-arrow-left::before {
  font-family: "primeicons";
  content: "\e900";
  font-weight: bold;
}

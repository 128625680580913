.secondary_button {
  color: $primaryColor !important;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-color: $primaryColor !important;
}

.secondary_button:hover {
  background-color: $primaryColor !important;
  color: white !important;
}

.secondary_button:focus {
  box-shadow: none;
}

.primary_button {
  background-color: $DODGER_BLUE !important;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-color: $DODGER_BLUE !important;
}
